var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8Up0BUWZK6z-bbHeGSgzl"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { captureConsoleIntegration } from '@sentry/integrations'

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: 'https://7b4eb8cc1d5953fd79862ccdfd9d7907@o483584.ingest.sentry.io/4505981822959616',

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  integrations: [captureConsoleIntegration({ levels: ['error'] })],
})
